import React from 'react';

//sitewise layer icon
const RotationClockwiseIcon = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 19 18"
        fill="currentColor"
    >
        <path d="M17.5832 2.73065e-07H15.89C15.832 -6.5237e-05 15.7745 0.0116571 15.7211 0.0344566C15.6678 0.0572561 15.6196 0.0906575 15.5795 0.132636C15.5394 0.174614 15.5083 0.224294 15.488 0.278663C15.4677 0.333033 15.4587 0.390959 15.4614 0.448929L15.6043 3.40464C14.7737 2.42589 13.7398 1.63985 12.5746 1.10122C11.4094 0.56259 10.1408 0.284301 8.85714 0.285714C3.97643 0.285714 -0.00356903 4.26893 2.40172e-06 9.14964C0.00357383 14.0382 3.96786 18 8.85714 18C11.0504 18.0031 13.1662 17.1894 14.7921 15.7175C14.8356 15.6786 14.8706 15.6313 14.8951 15.5784C14.9197 15.5255 14.9331 15.4682 14.9347 15.4099C14.9363 15.3517 14.926 15.2937 14.9045 15.2395C14.8829 15.1854 14.8505 15.1362 14.8093 15.095L13.595 13.8807C13.5181 13.8039 13.415 13.7591 13.3064 13.7555C13.1978 13.7518 13.0918 13.7896 13.01 13.8611C12.0505 14.7052 10.8565 15.2369 9.58719 15.3851C8.31785 15.5334 7.03348 15.2912 5.90529 14.6909C4.7771 14.0906 3.8587 13.1606 3.27251 12.025C2.68633 10.8894 2.46021 9.60211 2.62431 8.33473C2.78841 7.06734 3.33492 5.88008 4.191 4.93122C5.04707 3.98236 6.17204 3.31699 7.41591 3.02379C8.65979 2.7306 9.96349 2.82352 11.1532 3.29017C12.3429 3.75682 13.3622 4.57503 14.075 5.63571L10.4489 5.46178C10.391 5.45903 10.333 5.46807 10.2787 5.48837C10.2243 5.50867 10.1746 5.5398 10.1326 5.57988C10.0907 5.61995 10.0573 5.66814 10.0345 5.72151C10.0117 5.77487 9.99993 5.83232 10 5.89035V7.58357C10 7.69723 10.0451 7.80624 10.1255 7.88661C10.2059 7.96699 10.3149 8.01214 10.4286 8.01214H17.5832C17.6969 8.01214 17.8059 7.96699 17.8863 7.88661C17.9666 7.80624 18.0118 7.69723 18.0118 7.58357V0.428571C18.0118 0.314907 17.9666 0.205899 17.8863 0.125526C17.8059 0.0451532 17.6969 2.73065e-07 17.5832 2.73065e-07Z" fill="#021E4F"/>
    </svg>
);

export default RotationClockwiseIcon;
