import React from 'react';

//sitewise layer icon
const RotationAntiClockwiseIcon = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 19 18"
        fill="currentColor"
    >
        <path d="M0.428291 2.73065e-07H2.1204C2.17839 -6.5237e-05 2.2358 0.0116571 2.28914 0.0344566C2.34247 0.0572561 2.39062 0.0906575 2.43067 0.132636C2.47072 0.174614 2.50183 0.224294 2.52212 0.278663C2.5424 0.333033 2.55144 0.390959 2.54869 0.448929L2.40592 3.40464C3.23597 2.42589 4.26916 1.63985 5.43361 1.10122C6.59806 0.56259 7.86581 0.284301 9.14865 0.285714C14.0262 0.285714 18.0036 4.26893 18 9.14964C17.9964 14.0382 14.0347 18 9.14865 18C6.95686 18.0031 4.84244 17.1894 3.21754 15.7175C3.17413 15.6786 3.1391 15.6313 3.1146 15.5784C3.09009 15.5255 3.07663 15.4682 3.07503 15.4099C3.07342 15.3517 3.08371 15.2937 3.10527 15.2395C3.12683 15.1854 3.1592 15.1362 3.2004 15.095L4.4139 13.8807C4.49069 13.8039 4.59379 13.7591 4.70233 13.7555C4.81087 13.7518 4.91674 13.7896 4.99851 13.8611C5.95736 14.7052 7.15057 15.2369 8.41908 15.3851C9.68759 15.5334 10.9711 15.2912 12.0986 14.6909C13.226 14.0906 14.1438 13.1606 14.7296 12.025C15.3154 10.8894 15.5414 9.60211 15.3774 8.33473C15.2134 7.06734 14.6673 5.88008 13.8117 4.93122C12.9562 3.98236 11.832 3.31699 10.5889 3.02379C9.34587 2.7306 8.04302 2.82352 6.85408 3.29017C5.66514 3.75682 4.64659 4.57503 3.93421 5.63571L7.55791 5.46178C7.61584 5.45903 7.67373 5.46807 7.72806 5.48837C7.7824 5.50867 7.83204 5.5398 7.87399 5.57988C7.91595 5.61995 7.94932 5.66814 7.97211 5.72151C7.99489 5.77487 8.00661 5.83232 8.00654 5.89035V7.58357C8.00654 7.69723 7.96142 7.80624 7.8811 7.88661C7.80078 7.96699 7.69184 8.01214 7.57825 8.01214H0.428291C0.314703 8.01214 0.205765 7.96699 0.125444 7.88661C0.0451241 7.80624 0 7.69723 0 7.58357V0.428571C0 0.314907 0.0451241 0.205899 0.125444 0.125526C0.205765 0.0451532 0.314703 2.73065e-07 0.428291 2.73065e-07Z" fill="#021E4F"/>
    </svg>
);

export default RotationAntiClockwiseIcon;


<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.428291 2.73065e-07H2.1204C2.17839 -6.5237e-05 2.2358 0.0116571 2.28914 0.0344566C2.34247 0.0572561 2.39062 0.0906575 2.43067 0.132636C2.47072 0.174614 2.50183 0.224294 2.52212 0.278663C2.5424 0.333033 2.55144 0.390959 2.54869 0.448929L2.40592 3.40464C3.23597 2.42589 4.26916 1.63985 5.43361 1.10122C6.59806 0.56259 7.86581 0.284301 9.14865 0.285714C14.0262 0.285714 18.0036 4.26893 18 9.14964C17.9964 14.0382 14.0347 18 9.14865 18C6.95686 18.0031 4.84244 17.1894 3.21754 15.7175C3.17413 15.6786 3.1391 15.6313 3.1146 15.5784C3.09009 15.5255 3.07663 15.4682 3.07503 15.4099C3.07342 15.3517 3.08371 15.2937 3.10527 15.2395C3.12683 15.1854 3.1592 15.1362 3.2004 15.095L4.4139 13.8807C4.49069 13.8039 4.59379 13.7591 4.70233 13.7555C4.81087 13.7518 4.91674 13.7896 4.99851 13.8611C5.95736 14.7052 7.15057 15.2369 8.41908 15.3851C9.68759 15.5334 10.9711 15.2912 12.0986 14.6909C13.226 14.0906 14.1438 13.1606 14.7296 12.025C15.3154 10.8894 15.5414 9.60211 15.3774 8.33473C15.2134 7.06734 14.6673 5.88008 13.8117 4.93122C12.9562 3.98236 11.832 3.31699 10.5889 3.02379C9.34587 2.7306 8.04302 2.82352 6.85408 3.29017C5.66514 3.75682 4.64659 4.57503 3.93421 5.63571L7.55791 5.46178C7.61584 5.45903 7.67373 5.46807 7.72806 5.48837C7.7824 5.50867 7.83204 5.5398 7.87399 5.57988C7.91595 5.61995 7.94932 5.66814 7.97211 5.72151C7.99489 5.77487 8.00661 5.83232 8.00654 5.89035V7.58357C8.00654 7.69723 7.96142 7.80624 7.8811 7.88661C7.80078 7.96699 7.69184 8.01214 7.57825 8.01214H0.428291C0.314703 8.01214 0.205765 7.96699 0.125444 7.88661C0.0451241 7.80624 0 7.69723 0 7.58357V0.428571C0 0.314907 0.0451241 0.205899 0.125444 0.125526C0.205765 0.0451532 0.314703 2.73065e-07 0.428291 2.73065e-07Z" fill="#021E4F"/>
</svg>
